.navContainer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
    min-height: 60px;
    margin-left: 100px;
    background-color: rgba(var(--bgcol), 0.5);
    backdrop-filter: blur(5px);
}

.navItem{
    padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 20px;
	padding-right: 20px;
	font-weight: bold;
}

.inactive {
	color: rgb(var(--textcol));
	display: inline-block;
	position: relative;
}

.inactive:after {
	content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 3px;
    border-radius: 10px;
    bottom: 0;
    left: 0;
    background-color: var(--contrastCol);
    transform-origin: bottom;
    transition: transform 0.25s ease-out;
}

.inactive:hover:after{
	transform: scaleX(1);
    transform-origin: bottom;
}

.ham-container .checkbox{
    height: 32px;
    width: 32px;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 5;
    display: block;
    opacity: 0;
}

.ham-container .checkbox:focus{
    z-index: -5;
}

.ham-container .hamburger-lines{
    height: 26px;
    width: 32px;
    position: absolute;
    top: 22px;
    right: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ham-container .hamburger-lines .line{
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: rgb(var(--textcol));
}

.ham-container .hamburger-lines .line1{
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
}

.ham-container .hamburger-lines .line2{
    transition: transform 0.2s ease-in-out;
}

.ham-container .hamburger-lines .line3{
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
}

.ham-container .checkbox:focus ~ .hamburger-lines .line1{
    transform: rotate(45deg);
}

.ham-container .checkbox:focus ~ .hamburger-lines .line2{
    transform:scaleY(0);
}

.ham-container .checkbox:focus ~ .hamburger-lines .line3{
    transform: rotate(-45deg);
}

.ham-container .checkbox:focus ~ .navContainer .navItem{
    transform: translate(0%);
}


.logoBox{
	height: fit-content;
	z-index: 1;
}

.logo{
	transform: scale();
	max-height: 60px;
}

.spacer{
    min-height: 64px;
}

.ref {
	text-decoration: none;
	font-weight: bold;
}

@media (min-width: 42em){
    .ham-container{
        position: sticky;
        top: 0;
        padding: 0 0 .15em;
        z-index: 10;
    }

	.ham-container .hamburger-lines{
		display: none;
	}

    .ham-container .logoBox{
        position: relative;
        height: auto;
        margin-top: 0.25em;
    }

    .ham-container .spacer{
        display: none;
    }

    .ham-container .navContainer{
        display: flex;
        justify-content: space-evenly;
        margin-left: 0;
    }

    .navItem{
        min-width: 5em;
        display: flex;
        justify-content: center;
    }

    .logoBox2{
        display: none;
    }

    .navContainer::after{
        content: "";
        position: absolute;
        top: calc(4em);
        width: 100%;
        background-image: linear-gradient(to top, var(--contrastCol) 5%, transparent 100%);
        opacity: 0.75;
        height: .25em;
    }
}

@media (max-width: 42em){
	.ham-container .hamburger-lines{
		display: flex;
	}

    .ham-container .navContainer{
        position: absolute;
        display: block;
        flex-direction: column;
        justify-content: space-between;
        top: calc(.5em + 70px);
        height: 0px;
        width: 100%;
        transform: scaleY(0);
        transform-origin:top;
        margin-left: 0px;
        transition: all 0.4s ease-in-out;
    }

    .ham-container .navContainer .ref{
        display: flex;
        justify-content: center;
        margin: .35em;
    }

    .logoBox2{
        position:absolute;
        top: .5em;
        left: 50%;
        display: block;
        align-items: center;
        margin-left: -35px;
        width: 70px;
        justify-content: center;
    }
    
    .ham-container .spacer{
        display: block;
    }

    .ham-container .checkbox:focus ~ .navContainer{
        display: block;
        height: 18.5em;
        z-index: 100;
        transform: scaleY(1);
    }

    .ham-container .checkbox:focus ~ .navContainer::after{
        display: block;
        min-height: 2em;
        background-image: linear-gradient(to bottom, rgb(var(--bgcol)) 25%, transparent 100%);
        content: "";
    }

    .logoBox{
        display: none;
    }
}