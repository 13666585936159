/*All styles*/
:root{
    --bgcol: 237, 237, 237;
    --white: 255, 255, 255;
    --shadowcol: rgba(51,51,51,0.1);
    --contrastCol:rgb(244, 160, 49);
    --moreviewoverlaycol:rgba(237, 237, 237, 0.702);
    --blockquote: 61, 107, 204;
    --blockquotehg: 68, 68, 68;
    --textcol: 19, 19, 19;
}

@media (prefers-color-scheme: dark){
    :root{
    --bgcol: 31, 32, 33;
    --white: 19, 19, 19;
    --t: rgb(32, 32, 32);
    --shadowcol: rgba(51, 51, 51, 0);
    --contrastCol: rgba(244, 137, 49, 0.824);
    --moreviewoverlaycol:rgba(39, 39, 39, 0.702);
    --blockquote: 61, 107, 204;
    --blockquotehg: 68, 68, 68;
    --textcol: 237, 237, 237;
    }

    a{
    color: rgb(var(--blockquote));
    }
}

html{
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(var(--bgcol));
    color: rgb(var(--textcol));
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.centered{
    display: flex;
    justify-content: center;
}

.ContentArea{
    display: flex;
    margin: 1em;
}

.content-pane{
    background-color: rgb(var(--white));
    border-radius: 20px 20px 20px 20px;
    align-items: center;
    box-shadow: var(--shadowcol) 0px 4px 32px;
    min-height: min-content;
    margin-left: auto;
    margin-right: auto;
}

.texttype{
    padding: 2px 1em 2px 1em;
}

.blue{
    border-color: var(--contrastCol);
    border-width: 1px;
}

.content{
    margin-left: 1em;
    margin-right: 1em;
    width: 100%;
    min-height: calc(100vh - 21.75em);
}

.nextto{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.nextto .content-pane{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    width: 32%;
    min-width: 15em;
    min-height: 10em;
}

.center-text{
    display: flex;
    text-align: center;
}

.center-elements{
    display: flex;
    justify-content: center;
}

.imageWelcome{
    max-width: 100%;
    max-height: 700px;
    padding: 1em 0em;
}

.imageAuto{
    max-width: 100%;
}

.image30em{
    max-width: 30em;
}

.imageInText{
    max-width: 25vw;
}

.imageInText.right{
    padding-left: .5em;
}

.imageInText.left{
    padding-right: .5em;
}

audio{
    border-radius: 1em;
    width: 100%;
    max-width: 40em;
    margin: .5em 0;
}

@media (max-width: 42em){
    .ContentArea{
    display: block;
    }

    .image30em{
    max-width: 100%;
    }

    .imageInText{
    max-width: 100%;
    padding-bottom: .5em;
    }
}

@media (max-width: 48em){
    .nextto .element-wrapper{
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    }
}

blockquote {
	border-left:2px solid rgb(var(--blockquote));
	color: rgb(var(--blockquotehg));
	display:block;
	line-height:20px;
	margin:0 1em 0 1em;
	padding:0 1em 0 1em;
	color: rgb(var(--textcol));
}

.map{
	width: 90%;
	margin-left: 5%;
	margin-right: 5%;
	max-width: 50em;
}

.galleryContainer{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px);
    margin-bottom: 1em;
}

.galleryItem1{
    display: flex;
    align-items: center;
    grid-column: 1/9;
    grid-row: 1/7;
    overflow: hidden;
    background-color: rgb(var(--bgcol));
    height: 0;
    padding-bottom: 37.5%;
    padding-top: 37.5%;
}

.galleryItem2{
    display: flex;
    align-items: center;
    overflow: hidden;
    grid-column: 9/13;
    grid-row: 1/4;
    background-color: rgb(var(--bgcol));
    height: 0;
    padding-bottom: 37.5%;
    padding-top: 37.5%;
}

.galleryItem3{
    display: flex;
    align-items: center;
    overflow: hidden;
    grid-column: 9/13;
    grid-row: 4/7;
    position: relative;
    background-color: rgb(var(--bgcol));
    height: 0;
    padding-bottom: 37.5%;
    padding-top: 37.5%;
}

.genericGalleryItem{
    display: flex;
    align-content: center;
    justify-content: center;
    max-height: 40em;
    background-color: rgb(var(--bgcol));
}

.galleryImage{
    width: 100%;
}

.subGalleryImage{
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
}

.fullScreenImgPos{
    display: block;
    visibility: hidden;
    position: fixed;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    background-color: var(--moreviewoverlaycol);
    z-index: 20;
}

.closebtnborder{
    position: absolute;
    height: 24px;
    width: 24px;
    top: 20px;
    right: 20px;
}

.x-stroke3{
    border-radius: 20px;
    width: 32px;
    height: 4px;
    background-color: rgb(var(--textcol));
    position: absolute;
    top: 10px;
    left: -4px;
    transform-origin: 50% 50%;
    transform: rotate(45deg);
}

.x-stroke4{
    border-radius: 20px;
    width: 32px;
    height: 4px;
    background-color: rgb(var(--textcol));
    position: absolute;
    bottom: 10px;
    left: -4px;
    transform-origin: 50% 50%;
    transform: rotate(-45deg);
}

.x-container{
    height: 100%;
    margin-top: 2px;
    display: flex;
    justify-content: center;
}

.full-toggle{
    position: absolute;
    height: 24px;
    width: 24px;
    top: 20px;
    right: 20px;
    display: block;
    z-index: 10;
    opacity: 0;
}
.fullScreenImg{

    max-width: 95%;
    max-height: 95%;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    -o-object-fit: contain;
    object-fit: contain;
}


.galleryLink{
    position: absolute;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #000000;
}

.viewXMore{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--moreviewoverlaycol);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: rgb(var(--textcol));
}

.paddingtop1em{
    padding-top: 1em;
}

.galleryContainer :nth-child(odd).genericGalleryItem{
    grid-column: 1/7;

}

.galleryContainer :nth-child(even).genericGalleryItem{ 
    grid-column: 7/13;;
}

.spacerGall{
    min-height: 1em;
}

summary{
    font-size: larger;
    font-weight: bolder;
    padding-bottom: 1em;
    padding-top: 1em;
}

@media (max-width: 68em){
    .galleryItem1{
        grid-column: 1/13;
        grid-row: 1/10;
    }

    .galleryItem2{
        grid-column: 1/7;
        grid-row: 10/12;
    }

    .galleryItem3{
        grid-column: 7/13;
        grid-row: 10/12;
    }

    .galleryContainer :nth-child(odd).genericGalleryItem{
        grid-column: 1/13;
    
    }

    .galleryContainer :nth-child(even).genericGalleryItem{ 
        grid-column: 1/13;;
    }
}

.NewsContainer{
    max-width: 30em;
}

.img{
    width: 90%;
    margin: 5%;
}

img{
    object-fit: contain;
}

.information-icon-border{
    position: absolute;
    height: 24px;
    width: 24px;
    top: 20px;
    right: 20px;
    display: block;
    border-radius: 20px;
    border-width: 4px;
    border-color: rgb(var(--textcol));
    border-style: solid;
    transition: border 0.2s ease-in-out;
}

.info-toggle{
    position: absolute;
    height: 24px;
    width: 24px;
    top: 20px;
    right: 20px;
    display: block;
    z-index: 10;
    opacity: 0;
}

.i-container{
    height: 100%;
    margin-top: 2px;
    display: flex;
    justify-content: center;
}
.i-dot{
    border-radius: 20px;
    width: 4px;
    height: 4px;
    background-color: rgb(var(--textcol));
    transition: background-color 0.4s ease-in-out;
}

.i-stroke{
    border-radius: 20px;
    margin-top: 3px;
    width: 4px;
    height: 12px;
    background-color: rgb(var(--textcol));
    transition: background-color 0.4s ease-in-out;
}

.x-stroke1{
    border-radius: 20px;
    width: 32px;
    height: 4px;
    background-color: #00000000;
    position: absolute;
    top: 10px;
    left: -4px;
    transform-origin: 50% 50%;
    transform: rotate(45deg);
    transition: background-color 0.4s ease-in-out;
}

.x-stroke2{
    border-radius: 20px;
    width: 32px;
    height: 4px;
    background-color: #00000000;
    position: absolute;
    bottom: 10px;
    left: -4px;
    transform-origin: 50% 50%;
    transform: rotate(-45deg);
    transition: background-color 0.4s ease-in-out;
}

@media (max-width: 42em){
    .NewsContainer{
        display: none;
    }

    .content{
        margin-left: 0;
    }

    .news-main input[type="checkbox"]:checked  ~ .NewsContainer{
        display: block;
        min-width: 100%;
        background-color: var(--moreviewoverlaycol);
        position: absolute;
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
        z-index: 10;
    }

    .news-main input[type="checkbox"]:checked  ~ .NewsContainer .content-pane{
        display: block;
        max-width: 90%;
        width: 250px;
    }

    .news-main input[type="checkbox"]:checked  ~ .NewsContainer h1{
        background-color: rgb(var(--bgcol));
    }

    .news-main{
        min-width: 100%;
        display: flex;
        justify-content: center;
    }

    .news-main input[type="checkbox"]:checked  ~ .information-icon-border{
        border-color: #00000000;
    }

    .news-main input[type="checkbox"]:checked  ~ .information-icon-border .i-dot{
        background-color: #00000000;
    }

    .news-main input[type="checkbox"]:checked  ~ .information-icon-border .i-stroke{
        background-color: #00000000;
    }

    .news-main input[type="checkbox"]:checked  ~ .information-icon-border .x-stroke1{
        background-color: rgb(var(--textcol));
    }

    .news-main input[type="checkbox"]:checked  ~ .information-icon-border .x-stroke2{
        background-color: rgb(var(--textcol));
    }

}

@media (min-width: 42em){
    .information-icon-border{
        display: none;
    }

    .info-toggle{
        display: none;
    }
}

.center-foot{
    padding-top: 10px;
    padding-bottom: 10px;
    display:inline-table;
    width: 100%;
    text-align: center;
    margin: 0;
}

.footer{
    display: block;
    width: 100%;
    background-color: rgb(var(--white));
    color: rgb(var(--textcol));
}

.navItem.light{
    font-weight: normal;
    padding: 0.25em;
}

.navItem.light:hover{
    font-weight: normal;
    text-decoration: underline;
}

.footer-column{
    display: flex;
    flex-direction: column;
}

.footer-column.text{
    line-height: 1.5em;
    padding-top: 0.2em;
}

.foot-parallel{
    display: flex;
    justify-content: center;
}

@media (max-width: 42em) {
    .foot-parallel{
        flex-direction: column;
        align-items: center;
    }

    .verticalrow{
        height: 1px;
        min-width: 90%;
        padding: .75em 0;
    }
}

.rowfill{
    background-color: rgb(var(--textcol));
    height: 100%;
    width: 100%;
}

.verticalrow{
    
    width: 1px;
    margin: 0 1em;
}

.silentLink{
    text-decoration: none;
    color: rgb(var(--textcol));
}
